import React from "react";
import advantages from "./advantages";
import Image from "next/image";

export default function Advantages() {
  return (
    <div id="advantages" className="align-center pt-[3.6em] relative">
      <h1 className="font-extralight text-[12vw] md:text-6xl text-center font-akrobat pb-8">
        Competitive Advantage
      </h1>
      <div className="mx-12 md:mx-0 flex flex-col items-center md:flex-row md:flex-wrap md:content-center md:px-[10%] justify-center">
        {advantages.map((adv, i) => (
          <div
            key={i}
            className="mt-8 text-center self-start odd:self-end md:odd:self-start basis-3/12 px-2"
          >
            <Image
              src={adv.img}
              alt={adv.name}
              style={{ width: "auto" }}
              className="h-32 md:h-20 m-auto "
            />
            <p className="max-w-xs mt-4 text-lg md:text-lg font-akrobat">
              {adv.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
