import React from "react";
import steps from "./steps";
import { classNames } from "../../lib/tools";

export default function MobileSteps() {
  return (
    <div className="flex flex-col items-center mx-16 sm:mx-32 lg:hidden">
      {steps.map((step, i) => (
        <div key={i} className="howwework-card font-akrobat">
          <h1 className="text-[#27a7df] text-[3.25em]">{i + 1}</h1>
          <h3 className="my-[0.75em] font-bold text-2xl">{step.name}</h3>
          <p className="text-xl">{step.text}</p>
        </div>
      ))}
    </div>
  );
}
