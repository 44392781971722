import Link from "next/link";
import React from "react";

const Background = ({ disabled, ...props }) => (
  <svg
    viewBox="0 0 266 67"
    preserveAspectRatio="none"
    width="100%"
    height="100%"
    className={`absolute left-0 top-0 w-full h-full z-[-1] ${
      disabled ? "fill-[#f7d365]" : "fill-[#fdcd39]"
    }`}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M5.905 4.052L259.717.044a6 6 0 0 1 6.08 6.432l-3.979 54.957A6 6 0 0 1 255.834 67H6a6 6 0 0 1-6-6V10.052a6 6 0 0 1 5.905-6z"
    />
  </svg>
);

export default function SubmitButton({ text, disabled, form_id, ...props }) {
  return (
    <div
      as={props.to ? Link : props.href ? "a" : "button"}
      {...props}
      className={`transition ease-in-out cursor-pointer relative z-10 leading-10 flex justify-center items-center w-3/4 text-center md:inline-block md:w-auto md:px-11 hover:scale-110 m-auto h-20 ${
        disabled ? "cursor-none" : null
      }`}
      form={form_id}
    >
      <Background disabled={disabled} />
      <input
        type="submit"
        value={disabled ? "Submitting..." : text}
        className="font-bold font-akrobat text-white text-2xl cursor-pointer w-full h-full"
      />
    </div>
  );
}
