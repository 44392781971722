import React from "react";
import HomeDesktopFooter from "./HomeDesktopFooter";
import HomeMobileFooter from "./HomeMobileFooter";

export default function HomeFooter() {
  return (
    <div id="contact">
      <HomeDesktopFooter />
      <HomeMobileFooter />
    </div>
  );
}
