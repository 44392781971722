import Offer1Icon from "./Offer1/Offer1Icon";
import Offer2Icon from "./Offer2/Offer2Icon";
import Offer3Icon from "./Offer3/Offer3Icon";
import Offer4Icon from "./Offer4/Offer4Icon";
import HomeOffer1 from "./Offer1";
import HomeOffer2 from "./Offer2";
import HomeOffer3 from "./Offer3";
import HomeOffer4 from "./Offer4";

const offers = [
  {
    name: "SERVICE PROVIDERS",
    color: "#e5375e",
    Icon: Offer1Icon,
    Details: HomeOffer1,
    class: "w-[55%] left-[50%] h-full",
  },
  {
    name: "HOMEOWNERS",
    color: "#72c59e",
    Icon: Offer2Icon,
    Details: HomeOffer2,
    class: "w-[55%] left-[50%] h-full",
  },
  {
    name: "DEVELOPERS",
    color: "#ed6d4d",
    Icon: Offer3Icon,
    Details: HomeOffer3,
    class: "w-[55%] left-[50%] h-full",
  },
  {
    name: "BUSINESSES",
    color: "#228ea4",
    Icon: Offer4Icon,
    Details: HomeOffer4,
    class: "w-[55%] h-full left-[10%] relative",
  },
];

export default offers;
