import React from "react";

export default function HomeContact() {
  return (
    <div className="text-white font-akrobat text-center">
      <h1 className="text-5xl md:text-6xl">CONTACT</h1>
      <p className="mt-10 text-xl">+27 87 806 6094</p>
      <p className="mt-3 text-xl">info@lighttree.co.za</p>
      <p className="mt-6 text-xl">
        A3 Anansi Park <br />
        25 Church Street <br />
        Durbanville
        <br />
        Cape Town <br />
      </p>
    </div>
  );
}
