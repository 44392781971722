import React from "react";
import { classNames } from "../../lib/tools";

export default function OfferTitle({ name, Icon, update, index, ...props }) {
  return (
    <div className="relative w-full h-full flex justify-left ring-transparent mb-16">
      <b className="absolute top-1/2 text-center text-2xl z-10">{name}</b>
      <Icon
        fill={props.color}
        stroke={props.color}
        className={"w-[30%] absolute -top-10 -left-20 h-auto"}
      />
    </div>
  );
}
