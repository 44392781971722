import React, { useState, useRef } from "react";
import * as yup from "yup";
import axios from "axios";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import SubmitButton from "../Molecules/SubmitButton";
import RadioGroup from "../Molecules/RadioGroup";
import Input from "../Molecules/Input";

const ContactSchema = yup.object().shape({
  email: yup.string().required("Email Required").email("Invalid Email"),
  name: yup.string().required("Name Required"),
  phone: yup
    .number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .min(8)
    .required("A phone number is required"),
  message: yup.string().required("Message required"),
});

export default function HomeForm() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  let radioRef = useRef();
  let loadingToastId;

  async function handleContactSubmit(formdata) {
    const data = {
      date: new Date().toDateString(),
      subject: "📧Lighttree Website - Contact Us",
      ...formdata,
    };
    try {
      setLoading(true);
      loadingToastId = toast.loading("💾 Saving...");
      const result = await axios.post(`api/contactus`, data);
      toast.dismiss(loadingToastId);
      toast.success("Message Sent");
      setLoading(false);
    } catch (error) {
      toast.dismiss(loadingToastId);
      setLoading(false);
      console.log("There was a problem sending the message", error);
      toast.error(`There was a problem sending the message ${error.message}`);
    }
  }
  const onSubmit = (data) => {
    console.table({ ...data, type: radioRef.current.name });
    handleContactSubmit({ ...data, type: radioRef.current.name });
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="contactsubmit"
        className="flex flex-col"
      >
        <div className="lg:max-w-[18rem]">
          <div className="flex flex-wrap box-border justify-center w-full">
            <div className="box-border flex-grow w-max basis-full p-3 m-0 text-center text-white text-4xl font-akrobat">
              Form
            </div>
            <div className="box-border flex-grow w-max basis-full p-3 m-0">
              <input
                {...register("name", { required: true })}
                placeholder="Name"
                className={`block w-full rounded-md border-gray-300 focus:border-[#fdcd39] focus:ring-[#fdcd39] sm:text-sm p-3 ${
                  loading ? "text-gray-500 bg-gray-50" : "text-gray-900"
                }`}
              />
              {errors.name && (
                <span className="pt-8 text-[#fdcd39] font-bold font-akrobat text-left">
                  This field is required
                </span>
              )}
            </div>
            <div className="box-border flex-grow w-max basis-full p-3 m-0 relative mt-1">
              <input
                {...register("surname", { required: true })}
                placeholder="Surname"
                className={`block w-full rounded-md border-gray-300 focus:border-[#fdcd39] focus:ring-[#fdcd39] sm:text-sm p-3 ${
                  loading ? "text-gray-500 bg-gray-50" : "text-gray-900"
                }`}
              />
              {errors.surname && (
                <span className="pt-8 text-[#fdcd39] font-bold font-akrobat">
                  This field is required
                </span>
              )}
            </div>
            <div className="box-border flex-grow w-max basis-full p-3 m-0">
              <input
                {...register("phone", { required: true })}
                placeholder="Phone"
                className={`block w-full rounded-md border-gray-300 focus:border-[#fdcd39] focus:ring-[#fdcd39] sm:text-sm p-3 ${
                  loading ? "text-gray-500 bg-gray-50" : "text-gray-900"
                }`}
              />
              {errors.phone && (
                <span className="pt-8 text-[#fdcd39] font-bold font-akrobat">
                  This field is required
                </span>
              )}
            </div>
            <div className="box-border flex-grow w-max basis-full p-3 m-0">
              <input
                {...register("email", { required: true })}
                placeholder="Email"
                className={`block w-full rounded-md border-gray-300 focus:border-[#fdcd39] focus:ring-[#fdcd39] sm:text-sm p-3 ${
                  loading ? "text-gray-500 bg-gray-50" : "text-gray-900"
                }`}
              />
              {errors.email && (
                <span className="pt-8 text-[#fdcd39] font-bold font-akrobat">
                  This field is required
                </span>
              )}
            </div>
            <div className="box-border flex-grow w-max basis-full p-3 m-0">
              <textarea
                {...register("message", { required: true })}
                placeholder="Message"
                className={`block w-full rounded-md border-gray-300 focus:border-[#fdcd39] focus:ring-[#fdcd39] sm:text-sm p-3 ${
                  loading ? "text-gray-500 bg-gray-50" : "text-gray-900"
                }`}
              />
              {errors.phone && (
                <span className="pt-8 text-[#fdcd39] font-bold font-akrobat">
                  This field is required
                </span>
              )}
            </div>

            <div className="box-border flex-grow w-max basis-full p-3 m-0">
              <RadioGroup radioRef={radioRef} />
            </div>
          </div>
        </div>
        <SubmitButton
          text="Submit"
          disabled={loading}
          type="submit"
          form_id={"contactsubmit"}
        />
      </form>
    </div>
  );
}
