import React from "react";
import Image from "next/image";
import dbg from "../../../public/assets/svg/home-last-dbg.svg";
import treeImg from "../../../public/assets/svg/home-footer.svg";
import ContactCard from "./ContactCard";
import FormCard from "./FormCard";

export default function DesktopFooter() {
  return (
    <div className="hidden lg:block mt-14 relative">
      <Image src={dbg} alt="ContactFormImage" />
      <div className="flex justify-center items-center absolute top-0 left-0 bottom-1/2 right-0">
        <ContactCard />
        <FormCard />
      </div>
      <div className="flex items-center flex-col justify-between absolute top-1/2 left-0 right-0 bottom-0">
        <Image
          src={treeImg}
          alt="ContactFormLogo"
          className="block h-1/2 mt-[10%]"
        />
        <small className="block text-sm text-center mb-12">
          All rights reserved. 2019.
        </small>
      </div>
    </div>
  );
}
