const agg = [
  {
    $lookup: {
      from: "Onts",
      localField: "ontId",
      foreignField: "_id",
      as: "ont",
    },
  },
  {
    $lookup: {
      from: "Products",
      localField: "productId",
      foreignField: "_id",
      as: "product",
    },
  },
  {
    $lookup: {
      from: "Vlans",
      localField: "vlanId",
      foreignField: "_id",
      as: "vlan",
    },
  },
  {
    $unwind: {
      path: "$ont",
    },
  },
  {
    $unwind: {
      path: "$product",
    },
  },
  {
    $unwind: {
      path: "$vlan",
    },
  },
  {
    $lookup: {
      from: "Isps",
      localField: "vlan.ispId",
      foreignField: "_id",
      as: "isp",
    },
  },
  {
    $unwind: {
      path: "$isp",
    },
  },
  {
    $lookup: {
      from: "Addresses",
      localField: "ont.addressId",
      foreignField: "_id",
      as: "address",
    },
  },
  {
    $unwind: {
      path: "$address",
    },
  },
  {
    $lookup: {
      from: "Sites",
      localField: "address.siteId",
      foreignField: "_id",
      as: "site",
    },
  },
  {
    $unwind: {
      path: "$site",
    },
  },
  {
    $lookup: {
      from: "Residents",
      localField: "address.residentId",
      foreignField: "_id",
      as: "resident",
    },
  },
  {
    $unwind: {
      path: "$resident",
    },
  },
  {
    $project: {
      status: 1,
      ont_sn: "$ont.serial_number",
      address_complex: "$site.site_name",
      address_unit: "$address.unit_no",
      address_id: { $toString: "$address._id" },
      product_name: "$product.name",
      product_id: { $toString: "$productId" },
      isp: "$isp.name",
      isp_id: { $toString: "$isp._id" },
      vlan_id: { $toString: "$vlanId" },
      vlan_number: "$vlan.vlan_number",
      resident: {
        first_name: 1,
        last_name: 1,
        email: 1,
        phone: 1,
        _id: { $toString: "$resident._id" },
      },
    },
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const fetcher = (...args) => fetch(...args).then((res) => res.json());

export const flattenObj = (ob) => {
  // The object which contains the
  // final result
  let result = {};

  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        // Store temp in result
        result[i + "." + j] = temp[j];
      }
    }

    // Else store ob[i] in result directly
    else {
      result[i] = ob[i];
    }
  }
  return result;
};

export { agg, classNames, fetcher };
