import React from "react";
import styles from "./homeoffers.module.css";
import DesktopOffers from "./DesktopOffers";
import MobileOffers from "./MobileOffers";

export default function HomeOffers() {
  return (
    <div
      id="our-clients"
      className="px-[1em] py-[5em] md:pt-[3.6em] md:px-0 md:pb-0"
    >
      <h1 className="text-black font-extralight text-[12vw] text-center md:text-6xl font-akrobat">
        WHAT WE OFFER TO:
      </h1>
      <DesktopOffers />
      <MobileOffers />
    </div>
  );
}
