import React from "react";
import offerImg from "../../../public/assets/svg/home-offer2.svg";
import OfferDetails from "../OfferDetails";

const HomeOffer2 = () => (
  <OfferDetails alternate img={offerImg}>
    <p className="font-bold">
      Our fibre connectivity allows for an internet speed of up to 1Gbps. Not
      only is fibre connectivity going to boost the value of your property, it’s
      also going to make life a lot easier
    </p>
    <div>
      <ul className="list-disc">
        <li>Fast installation and steady support</li>
        <li>Open access</li>
        <li>Low latency </li>
        <li>DStv and OpenView compatibility</li>
      </ul>
      <ul className="list-disc">
        <li>HD without the frustration of buffering</li>
        <li>Clear, uninterrupted voice calls</li>
        <li>Lag-free gaming</li>
        <li>Sweet, sweet relief</li>
      </ul>
    </div>
  </OfferDetails>
);

export default HomeOffer2;
