import React, { memo, useState } from "react";

const Offer1Icon = ({ ...props }) => {
  return (
    <svg height="100%" viewBox="0 0 109.7 78" {...props}>
      <path d="M.1 43c0-.1-.1-.2-.1-.3L105.7 0l.1.3c11.3 27.9-2.8 59.9-31.4 72l-.9.4C44.4 84.5 11.5 71.2.1 43z" />
    </svg>
  );
};

export default memo(Offer1Icon);
