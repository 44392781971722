import React, { useState } from "react";

export default function Input({
  label,
  type,
  placeholder,
  defaultValue,
  onChange,
  name,
  disabled,
}) {
  return (
    <div>
      <label
        htmlFor={label.toLowerCase()}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          type="text"
          disabled={disabled}
          name={name.toLowerCase()}
          id={label.toLowerCase()}
          className={`block w-full rounded-md border-gray-300 focus:border-[#fdcd39] focus:ring-[#fdcd39] sm:text-sm ${
            disabled ? "text-gray-500 bg-gray-50" : "text-gray-900"
          }`}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
