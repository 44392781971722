import Image from "next/image";
import React from "react";
import HomeContact from "../HomeContact";
import HomeForm from "../HomeForm";
import treeImg from "../../../public/assets/svg/home-footer.svg";

export default function MobileFooter() {
  return (
    <div className="py-4 lg:hidden relative ">
      <div className="bg-[#73c59e] w-full px-4 py-10 relative my-3 before:absolute after:absolute after:right-0 before:right-0 after:left-0 before:left-0 after:h-6 before:h-6 before:-top-6 mobilefooter">
        <HomeContact />
      </div>
      <div className="bg-[#1980ba] w-full px-4 py-10 relative my-3 before:absolute after:absolute after:right-0 before:right-0 after:left-0 before:left-0 after:h-6 before:h-6 before:-top-6 mobilefooter2">
        <HomeForm />
      </div>
      <Image
        src={treeImg}
        alt="lighttree_logo"
        className="block w-64 m-auto pt-16 pb-4"
        style={{
          maxWidth: "100%",
          height: "auto",
        }}
      />
      <small className="font-akrobat block m-auto text-center">
        All rights reserved. 2023.
      </small>
    </div>
  );
}

//    before:bg-gradient-to-br before:from-cyan-500 before:to-blue-400 before:-top-6
