import React from "react";
import offers from "./offers";
import OfferTitle from "./OfferTitle";

export default function MobileOffers() {
  return (
    <div className="md:hidden mt-10">
      {offers.map((offer) => (
        <div key={offer.name} className="mt-10">
          <OfferTitle {...offer} />
          <offer.Details />
        </div>
      ))}
    </div>
  );
}
