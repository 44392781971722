import React from "react";
import HomeForm from "../HomeForm";

export default function FormCard() {
  return (
    <div className="absolute left-[45%] xl:left-[48%] ml-4 mt-8 w-80 lg:ml-16 pt-36">
      <HomeForm />
    </div>
  );
}
