import { Tab } from "@headlessui/react";
import offers from "./offers";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabcomponent() {
  return (
    <div className="w-full px-2 py-16 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex rounded-xl p-1 ring-transparent">
          {offers.map(({ name, Icon, ...offer }) => (
            <Tab
              key={name}
              className={classNames("w-full py-2.5 m-8 ring-transparent")}
            >
              {({ selected }) => (
                <div className="relative w-full h-full flex justify-center ring-transparent">
                  <b className="absolute top-1/2 left-0 z-10">{name}</b>
                  <Icon
                    selected={selected}
                    fill={selected ? offer.color : "#fff"}
                    stroke={offer.color}
                    className={offer.class}
                  />
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {offers.map(({ name, ...offer }) => (
            <Tab.Panel key={name} className="">
              {offer.Details}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
