import React from "react";
import styles from "./homehero.module.css";
import Button from "../Button";

export default function HomeHero() {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h1 className={styles.hone}>
          WE ARE <br />
          LIGHTTREE <br />
          TECHNOLOGIES{" "}
        </h1>
        <h3 className={styles.hthree}>
          We provide affordable and innovative connectivity solutions for homes
          and businesses.
        </h3>
      </div>
      <div className="flex items-center justify-center mt-5">
        <Button text="Get In Touch" fill="#27a7df" href="#" />
      </div>
    </div>
  );
}
