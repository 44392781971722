import img1d from "../../public/assets/svg/home-step1d.svg";
import img2d from "../../public/assets/svg/home-step2d.svg";
import img3d from "../../public/assets/svg/home-step3d.svg";

import img1m from "../../public/assets/svg/home-step1m.svg";
import img2m from "../../public/assets/svg/home-step2m.svg";

const steps = [
  {
    name: "GET IN TOUCH",
    text: "We’ll help you figure out exactly what you need from fibre connectivity.",
    dbg: img1d,
    mbg: img1m,
  },
  {
    name: "INSTALLATION",
    text: "We offer prompt service and speedy installation.",
    dbg: img2d,
    mbg: img2m,
  },
  {
    name: "SERVICE ACTIVATION",
    text: "Speak to us about what your new connectivity can enable.",
    dbg: img3d,
    mbg: img1m,
  },
];

export default steps;
