import React from "react";
import offerImg from "../../../public/assets/svg/home-offer4.svg";
import OfferDetails from "../OfferDetails";

const HomeOffer4 = () => (
  <OfferDetails alternate img={offerImg}>
    <p className="font-bold">
      Our Switch-in-the-Cloud Solution allows for the connectivity of
      geographically dispersed offices to a single network, with private network
      activity and an internet speed of up to 1Gbps.
    </p>
    <div>
      <ul className="list-disc">
        <li>Fast installation and steady support</li>
        <li>Open access</li>
        <li>Low latency </li>
        <li>
          A colleague who downloads music and series instead of completing an
          urgent report
        </li>
        <li>
          HD Video Conferencing without the frustration of buffering or
          pixelated image
        </li>
      </ul>
      <ul className="list-disc">
        <li>Clear, uninterrupted voice calls</li>
        <li>
          A network primed to power offsite data-backups, CCTV, access control
          and smart meters
        </li>
        <li>
          Fully managed VOIP Services and Wireless Access Points for seamless
          connectivity
        </li>
      </ul>
    </div>
  </OfferDetails>
);

export default HomeOffer4;
