import img1 from "../../public/assets/svg/home-advantage1.svg";
import img2 from "../../public/assets/svg/home-advantage2.svg";
import img3 from "../../public/assets/svg/home-advantage3.svg";
import img4 from "../../public/assets/svg/home-advantage4.svg";
import img5 from "../../public/assets/svg/home-advantage5.svg";
import img6 from "../../public/assets/svg/home-advantage7.svg";
import img7 from "../../public/assets/svg/home-advantage6.svg";

const advantage = [
  { name: "Gigabit Internet Speeds", img: img1 },
  { name: "Crystal clear Voice (VOIP)", img: img2 },
  { name: "Smart Home and Business", img: img3 },
  { name: "Bespoke private Network solutions", img: img4 },
  { name: "DStv & OpenView compatibility", img: img5 },
  { name: "CCTV", img: img6 },
  { name: "Access Control ", img: img7 },
];

export default advantage;
