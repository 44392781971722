import React from "react";
import Tabcomp from "./Tabcomp";

export default function DesktopOffers() {
  return (
    <div className="hidden md:block py-0 px-[15%] content-center">
      <Tabcomp />
    </div>
  );
}
