import React from "react";
import offerImg from "../../../public/assets/svg/home-offer3.svg";
import OfferDetails from "../OfferDetails";

const HomeOffer3 = () => (
  <OfferDetails img={offerImg}>
    <p>
      Fibre connectivity is quickly becoming a necessity when it comes to
      choosing property. When property developers partner with us, they are able
      to market their property as fibre-ready. A fibre-ready property makes way
      for other selling points, such as fail-safe CCTV.
    </p>
  </OfferDetails>
);

export default HomeOffer3;
