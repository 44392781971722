import React from "react";
import offerImg from "../../../public/assets/svg/home-offer1.svg";
import OfferDetails from "../OfferDetails";

const HomeOffer1 = () => (
  <OfferDetails img={offerImg}>
    <p>
      Service providers can rely on our fibre network to power a range of
      products and services, including: wireless access points; private LANs;
      CCTV services, biometric access; water and electricity meters; and other
      IoT solutions.
    </p>
  </OfferDetails>
);

export default HomeOffer1;
