import React from "react";
import Link from "next/link";

const Background = ({ ...props }) => (
  <svg
    viewBox="0 0 266 67"
    preserveAspectRatio="none"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M5.905 4.052L259.717.044a6 6 0 0 1 6.08 6.432l-3.979 54.957A6 6 0 0 1 255.834 67H6a6 6 0 0 1-6-6V10.052a6 6 0 0 1 5.905-6z"
    />
  </svg>
);

export default function Button({ text, ...props }) {
  return (
    <Link
      className="relative leading-8 text-center h-12 w-48 hover:scale-105"
      href={props.href ? props.href : "#"}
    >
      <Background fill="#27a7df" className="absolute w-full top-0 h-full" />
      <span className="font-bold text-white relative top-2 font-akrobat text-xl">
        {text}
      </span>
    </Link>
  );
}
