import React from "react";
import { classNames } from "../../../lib/tools";

export default function StepTitle({ name, index, update, active, ...props }) {
  const handleClick = () => update(index);

  return (
    <div
      {...props}
      onClick={handleClick}
      className="flex basis-2/6 h-32 cursor-pointer justify-center font-akrobat align-middle items-center"
    >
      <h1 className="text-[#27a7df] text-[2.25rem]">{index + 1}</h1>
      <b
        className={classNames(
          "text-xl ml-4",
          active ? "font-extrabold" : "font-extralight"
        )}
      >
        {name}
      </b>
    </div>
  );
}
