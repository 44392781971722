import Image from "next/image";
import React from "react";

export default function OfferDetails({ children, ...props }) {
  return (
    <div className="block pt-[1rem] pb-[4rem] md:flex">
      <Image
        src={props.img}
        alt=""
        className="max-w-full mb-4 md:max-w-[50%] px-2"
      />
      <div className="md:max-w-[50%] font-akrobat leading-8 text-xl px-4">
        {children}
      </div>
    </div>
  );
}
