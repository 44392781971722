import React from "react";
import Layout from "../components/Layout";
import HomeHero from "../components/Hero";
import HomeAbout from "../components/About";
import HomeOffers from "../components/Offers/Index";
import HowWeWork from "../components/HowWeWork/Index";
import Advantages from "../components/Advantages/Index";
import HomeFooter from "../components/HomeFooter/HomeFooter";

export default function index() {
  return (
    <>
      <HomeHero />
      <HomeAbout />
      <HomeOffers />
      <HowWeWork />
      <Advantages />
      <HomeFooter />
    </>
  );
}
