import React, { useState } from "react";
import steps from "../steps";
import StepTitle from "./StepTitle";
import { classNames } from "../../../lib/tools";

export default function DesktopSteps() {
  const [selected, setSelected] = useState(0);
  const Selected = steps[selected];
  return (
    <div
      className={classNames(
        "hidden lg:flex flex-col steps_container h-96 xl:[24rem] 2xl:h-[28rem]",
        `steps_background-${selected + 1}`
      )}
    >
      <div className="flex items-center justify-center align-middle">
        {steps.map((step, i) => (
          <StepTitle
            key={step.name}
            {...step}
            active={selected === i}
            index={i}
            update={setSelected}
          ></StepTitle>
        ))}
      </div>
      <div className="pt-4 px-8 xl:px-16 lg:py-16 xl:py-24 2xl:py-32 font-akrobat text-xl 2xl:text-3xl">
        {Selected.text}
      </div>
    </div>
  );
}
