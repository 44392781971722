import React from "react";
import Button from "../Button";
import DesktopSteps from "./DeskopSteps/Index";
import MobileSteps from "./MobileSteps";
// import stepsMBg from "../../public/assets/svg/home-steps-mbg.svg";
// import stepsDBg from "../../public/assets/svg/home-steps-dbg.svg";

export default function HowWeWork() {
  return (
    <div id="how-we-work" className={`howwework`}>
      <h1 className="font-extralight text-[12vw] md:text-6xl text-center font-akrobat">
        HOW DO WE WORK WITH YOU?
      </h1>
      <DesktopSteps />
      <MobileSteps />
      <div className="flex items-center justify-center mb-5 ">
        <Button text="Get In Touch" fill="#27a7df" href="#" />
      </div>
    </div>
  );
}
