import React from "react";
import styles from "./homeabout.module.css";

export default function HomeAbout() {
  return (
    <div className={styles.container} id="about">
      <div className={styles.wrapper}>
        <h1 className="text-black font-extralight text-[12vw] md:text-6xl font-akrobat">
          About Us
        </h1>
        <p className={styles.paragraph}>
          We know that high-speed internet access can change everything, which
          is why we also{" "}
          <span className={styles.bespoke_1}>
            offer bespoke network solutions and data services
          </span>{" "}
          to help you get the most from your connectivity.
        </p>
        <p className={styles.paragraph}>
          We’ve been pioneers in fibre connectivity since 2014. We offer service
          providers, homeowners, property developers and businesses everything
          they need to stay connected and competitive.
        </p>
        <p className={styles.paragraph}>
          If you can’t stomach your one-bar WiFi anymore, get in touch now. (If
          you still know what dial-up sounds like, get in touch yesterday.)
        </p>
      </div>
    </div>
  );
}
