import React from "react";
import HomeContact from "../HomeContact";
import contactBg from "../../../public/assets/svg/home-contact-dbg.svg";

export default function ContactCard() {
  return (
    <div className={`px-20 pt-12 pb-16 desktop_contact_card`}>
      <HomeContact />
    </div>
  );
}
